// extracted by mini-css-extract-plugin
export var desktopMenuContainer = "menu-module--desktopMenuContainer--Ay0XB";
export var menu = "menu-module--menu--7o4WZ";
export var menuArrow = "menu-module--menuArrow--6XsXa";
export var menuTrigger = "menu-module--menuTrigger--zESGG";
export var mobileMenu = "menu-module--mobileMenu--qacuQ";
export var mobileMenuContainer = "menu-module--mobileMenuContainer--BAoMt";
export var mobileMenuOverlay = "menu-module--mobileMenuOverlay--IxrM5";
export var subMenu = "menu-module--subMenu--le7-+";
export var subMenuOverlay = "menu-module--subMenuOverlay--iaQWe";
export var subMenuTrigger = "menu-module--subMenuTrigger--6OW+2";
export var themeToggle = "menu-module--themeToggle--DwSMY";