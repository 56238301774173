import React from 'react'
import PropTypes from 'prop-types'
import * as style from '../styles/footer.module.css'
import {FaLinkedin} from "react-icons/fa"
import {FaEnvelope} from "react-icons/fa"
import {FaGithub} from "react-icons/fa"

const Footer = ({ copyrights }) => (
  <footer>
    <div className={style.footerWrapper}>
    {copyrights ? (
      <div
        dangerouslySetInnerHTML={{
          __html: copyrights,
        }}
      />
    ) : (
      <>
        <div className={style.icons}>
          <a href="https://linkedin.com/in/tinapoethe"><FaLinkedin /></a>
        </div>
        <div className={style.footerCopyrights}>
        &copy; <span>{new Date().getFullYear()}</span> Built by <a href="https://github.com/tpoet">Tina</a> with <a href="https://www.gatsbyjs.org">Gatsby</a>
        </div>
      </>
    )}
    </div>
  </footer>
)

Footer.propTypes = {
  copyrights: PropTypes.string,
}

export default Footer
